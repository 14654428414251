import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DeviceType,
    Divider,
    Testimonial,
    TestimonialType,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import socialPreview from '../../../static/social-previews/hopi.png';
import Seo from '../../components/SEO';

const team = [
    { department: 'references.case.team.management', names: ['Marek Přibáň', 'Ladislav Moravec', 'Dominik Veselý'] },
    { department: 'references.case.team.design', names: ['Michal Mikolaj', 'Jan Patka', 'Jiří Ryšánek'] },
    {
        department: 'references.case.team.backend',
        names: ['Lukáš Kočí', 'Libor Vilímek', 'Jiří Šmolík', 'Jaroslav Šmolík', 'Štefan Prokop'],
    },
    { department: 'references.case.team.web', names: ['Marek Janča', 'Jiří Čermák', 'Lukáš Horák', 'Jakub Baierl'] },
    {
        department: 'references.case.team.android',
        names: [
            'David Bilík',
            'David Khol',
            'Adam Vesecký',
            'Pavel Skala',
            'Dušan Jenčík',
            'Vlad Gorbunov',
            'Roman Polach',
            'Michal Pešek',
        ],
    },
    {
        department: 'references.case.team.devops',
        names: ['David Kotalík', 'Marek Bartík', 'Tomáš Hejátko', 'Štěpán Vraný', 'Martin Beránek'],
    },
    { department: 'references.case.team.testing', names: ['Tomáš Voldřich', 'Radek Kuchař', 'Maxim Rubáš'] },
];

const Hopi = () => (
    <>
        <Seo image={socialPreview} pageId="hopi" handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            headerTheme="light"
            content={{
                projectName: 'case.hopi.hero.project',
                projectTitle: 'case.hopi.hero.title',
                projectYear: 'case.hopi.hero.year',
                client: 'case.hopi.hero.client',
                image: {
                    src: 'references/case-studies/hopi/hero_device.png',
                    style: { height: '60%', width: '100%', marginTop: '3rem' },
                },
            }}
            backgroundImage={{
                src: 'references/case-studies/hopi/0_bg.png',
                imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
            }}
            background={'linear-gradient(0deg, #359056, #359056);'}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.hopi.section1.name',
                title: `case.hopi.section1.title`,
                text: `case.hopi.section1.text`,
                outputs: [
                    'references.case.output.android',
                    'references.case.output.web',
                    'references.case.output.design',
                    'references.case.output.backend',
                ],
            }}
        />
        <Gallery
            headerTheme="light"
            fullHeight
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: '#A7D743',
                    image: {
                        src: 'references/case-studies/hopi/2_1.png',
                        style: { width: '80%', height: '90%', display: 'block', margin: 'auto' },
                    },
                },
                {
                    background: '#00792C',
                    image: {
                        src: 'references/case-studies/hopi/2_2.png',
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: '#00792C',
                    image: {
                        src: 'references/case-studies/hopi/3_1.png',
                        imgStyle: { objectPosition: 'right bottom', objectFit: 'contain' },
                    },
                },
                {
                    background: '#3BA562',
                    image: {
                        src: 'references/case-studies/hopi/3_2.png',
                        style: { width: '80%', height: '80%', margin: 'auto' },
                        imgStyle: { objectFit: 'contain' },
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.hopi.section2.name',
                title: `case.hopi.section2.title`,
                text: `case.hopi.section2.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: '#3BA562',
                    image: {
                        src: 'references/case-studies/hopi/5_1.png',
                        imgStyle: { objectFit: 'scale-down', objectPosition: 'right bottom' },
                    },
                },
                {
                    background: '#00792C',
                    image: {
                        src: 'references/case-studies/hopi/5_2.png',
                        style: { width: '80%', height: '80%', margin: 'auto' },
                        imgStyle: { objectFit: 'contain' },
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: '#F5F5F5',

                    image: {
                        src: 'references/case-studies/hopi/6.png',
                        style: { width: '80%', height: '80%', display: 'block', margin: 'auto' },
                        imgStyle: { objectFit: 'contain' },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.hopi.section3.name',
                title: `case.hopi.section3.title`,
                text: `case.hopi.section3.text`,
            }}
        />
        <Carousel
            headerTheme="light"
            deviceType={DeviceType.MOBILE}
            background="linear-gradient(180deg, rgba(53,144,86,1) 0%, rgba(53,144,86,1) 50%, rgba(0,121,44,1) 50%, rgba(0,121,44,1) 100%);"
            slides={[
                {
                    image: {
                        src: 'references/case-studies/hopi/carousel-1.png',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/hopi/carousel-2.png',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/hopi/carousel-3.png',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/hopi/carousel-4.png',
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.hopi.section4.name',
                title: `case.hopi.section4.title`,
                text: `case.hopi.section4.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            backgroundImage={{ src: 'references/case-studies/hopi/4_bg.jpg', imgStyle: { objectFit: 'cover' } }}
            fullHeight
            content={[
                {
                    image: {
                        src: 'references/case-studies/hopi/4_1.jpg',
                        style: {
                            width: 'calc(100% - 8rem)',
                            height: 'calc(100% - 8rem)',
                            display: 'block',
                            margin: '4rem',
                        },
                        imgStyle: { objectFit: 'contain' },
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="dark"
            content={{
                quote: 'case.hopi.testimonial.text',
                person: {
                    name: 'case.hopi.testimonial.name',
                    role: 'case.hopi.testimonial.position',
                    image: {
                        src: 'references/case-studies/hopi/testimonial.jpg',
                    },
                },
            }}
            type={TestimonialType.PROFILE_BOTTOM}
        />
        <Team headerTheme="dark" content={team} />
    </>
);

export default Hopi;
